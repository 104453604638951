import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import UserInformationBodyModalStyles from "./UserInformationBodyModal.styles";
import { MdOutlineClose } from "react-icons/md";
import { selectCurrentUser } from "../../../store/slices/user";
import AnimationContent from "../../sharedComponents/AnimationContent";
import loadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import successAnimation from "../../../assets/lottieFiles/successAnimation.json";
import { STATUS_VARIANTS, TIPO_USUARIO_VARIANTS } from "./utils";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../../../store/slices/clients";
import useLogout from "../../../hooks/useLogout";

const UserinformationBodyModal = (props) => {
  const {
    handleClose,
    type,
    saveInformationHandler,
    loading,
    success,
    companies,
    availableRoutes,
  } = props;

  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
  const userInformation = useSelector(selectCurrentUser);
  const classes = UserInformationBodyModalStyles();
  const [currentUserInformation, setCurrentUserInformation] = useState({});
  const [selectedCompany, setSelectedCompany] = useState();
  const [handleEdit, setHandleEdit] = useState(false);
  const accesToken = useSelector(selectAccessToken);
  const [logout] = useLogout();
  const [shouldDisableFields, setshouldDisableFields] = useState(
    type === "view"
  );

  useEffect(() => {
    if (type && (type === "view" || type === "edit")) {
      setCurrentUserInformation({
        idUser: userInformation.id_user,
        username: userInformation.username,
        name: userInformation.name,
        password: userInformation.password,
        idUserType: userInformation.id_user_type,
        idRoute: parseInt(userInformation.id_route),
        status: userInformation.status,
        company_id: userInformation.company_id,
      });

      if (userInformation.id_user_type === 7) {
        setShowCompanyDropdown(true);
      }
      setSelectedCompany(userInformation.company_id);
    } else {
      setCurrentUserInformation({
        idUser: "",
        username: "",
        password: "",
        name: "",
        idUserType: 2,
        idRoute: 4,
        status: 1,
        company_id: 1,
      });
    }
  }, [type, userInformation]);

  const { username, name, idUserType, password, idRoute, status, company_id } =
    currentUserInformation;

  const disableWhenIsNew =
    !username || !name || !password || password.length < 8;

  const disableWhenIsEdit =
    !username ||
    !name ||
    !idUserType ||
    !idRoute ||
    !status ||
    !selectedCompany;

  const isView = type === "view";

  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentUserInformation((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });

    if (name === "idUserType") {
      setShowCompanyDropdown(parseInt(value) === 7);
    }

    if (name === "password") {
      if (value.length < 8) {
        setPasswordHelperText("La contraseña debe tener al menos 8 caracteres");
        setIsPasswordValid(false);
      } else {
        setPasswordHelperText("Contraseña válida");
        setIsPasswordValid(true);
      }
    }
  };

  const handleChangeCompany = (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);
    setCurrentUserInformation((prevState) => ({
      ...prevState,
      company_id: companyId,
    }));
  };

  const handleEditChange = () => {
    setHandleEdit(!handleEdit);
    if (isView) {
      setshouldDisableFields(!shouldDisableFields);
    }
  };

  const RenderUpdateButton = () => {
    return (
      <>
        {isView && !shouldDisableFields && (
          <Button
            className={classes.saveButton}
            type="contained"
            color="primary"
            onClick={() => saveInformationHandler(currentUserInformation)}
          >
            <Typography className={classes.updateInfoBtn}>
              Actualizar
            </Typography>
          </Button>
        )}
      </>
    );
  };

  const getCompanyIdById = (company_id) => {
    const company = companies.find(
      (company) => company.company_id === company_id
    );
    return company ? company.company_id : "";
  };

  const isCentered = loading || success;
  return (
    <Box
      className={classes.mainContainer}
      display={isCentered && "flex"}
      alignItems={isCentered && "center"}
      justifyContent={isCentered && "center"}
    >
      {loading ? (
        <Box>
          {success ? (
            <React.Fragment>
              <AnimationContent
                animationFile={success ? successAnimation : loadingAnimation}
              />
              <Typography align="center" variant="subtitle1">
                {type === "new"
                  ? "Usuario Creado con éxito"
                  : "Usuario actualizado con éxito"}
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <AnimationContent
                animationFile={success ? successAnimation : loadingAnimation}
              />
              <Typography align="center" variant="subtitle1">
                {type === "new" ? "Creando usuario" : "Actualizando usuario"}
              </Typography>
              <Typography align="center" variant="subtitle2">
                Por favor, no cierres la pantalla
              </Typography>
            </React.Fragment>
          )}
        </Box>
      ) : (
        <React.Fragment>
          <div className={classes.closeIconContainerMain} onClick={handleClose}>
            <IconButton>
              <MdOutlineClose />
            </IconButton>
          </div>
          <Box className={classes.contentContainer}>
            <Typography className={classes.titleBodyModal}>
              Información del usuario
            </Typography>
            <Typography className={classes.userInfoSubheader}>
              La información puede ser actualizada
            </Typography>

            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  className={classes.inputEnabled}
                >
                  Nombre
                </Typography>
                <TextField
                  disabled={shouldDisableFields}
                  variant="outlined"
                  name="name"
                  onChange={(e) => handleChange(e)}
                  value={name && name.toLowerCase()}
                  inputProps={{ className: classes.input }}
                  className={classes.inputContainer}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  className={classes.inputEnabled}
                >
                  Usuario
                </Typography>
                <TextField
                  disabled={shouldDisableFields}
                  variant="outlined"
                  name="username"
                  onChange={(e) => handleChange(e)}
                  value={username && username.toLowerCase()}
                  inputProps={{ className: classes.input }}
                  className={classes.inputContainer}
                />
              </Grid>
              {type === "new" && (
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    className={classes.inputEnabled}
                  >
                    Contraseña
                  </Typography>
                  <TextField
                    disabled={shouldDisableFields}
                    variant="outlined"
                    name="password"
                    onChange={handleChange}
                    value={password || ""}
                    inputProps={{ className: classes.input }}
                    className={classes.inputContainer}
                    helperText={passwordHelperText}
                    error={isPasswordValid === false}
                    FormHelperTextProps={{
                      style: {
                        color: isPasswordValid ? "green" : "red",
                        textAlign: "left",
                      },
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={6} className={classes.inputContainer}>
                <Typography
                  variant="subtitle2"
                  className={classes.inputEnabled}
                >
                  Tipo de usuario
                </Typography>
                <Select
                  disabled={shouldDisableFields}
                  variant="outlined"
                  name="idUserType"
                  value={idUserType ? parseInt(idUserType) : 2}
                  onChange={(e) => handleChange(e)}
                  style={{ width: 200 }}
                >
                  {TIPO_USUARIO_VARIANTS?.map((tipo) => (
                    <MenuItem key={tipo.id} value={tipo.id}>
                      {tipo.desc.toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6} className={classes.inputContainer}>
                <Typography
                  variant="subtitle2"
                  className={classes.inputEnabled}
                >
                  Ruta designada
                </Typography>
                <Select
                  disabled={shouldDisableFields}
                  variant="outlined"
                  name="idRoute"
                  value={parseInt(idRoute) || 4}
                  onChange={(e) => handleChange(e)}
                  style={{ width: 200 }}
                >
                  {availableRoutes && availableRoutes.length > 0 ? (
                    availableRoutes.map((route) => (
                      <MenuItem
                        key={parseInt(route.id_route)}
                        value={parseInt(route.id_route)}
                      >
                        {route.description
                          ? route.description.toLowerCase()
                          : "Sin descripción"}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No hay rutas disponibles</MenuItem>
                  )}
                </Select>
              </Grid>

              {showCompanyDropdown && (
                <Grid item xs={6} className={classes.inputContainer}>
                  <Typography
                    variant="subtitle2"
                    className={classes.inputEnabled}
                  >
                    Compañía
                  </Typography>
                  <Select
                    disabled={shouldDisableFields}
                    variant="outlined"
                    name="company_id"
                    value={selectedCompany || ""}
                    onChange={handleChangeCompany}
                    style={{ width: 200 }}
                    displayEmpty
                  >
                    <MenuItem disabled>Selecciona una compañía</MenuItem>
                    {companies && companies.length > 0 ? (
                      companies.map((company) => (
                        <MenuItem
                          key={company.company_id}
                          value={company.company_id}
                        >
                          {company.legal_name.toLowerCase()}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No hay compañías disponibles</MenuItem>
                    )}
                  </Select>
                </Grid>
              )}

              <Grid item xs={5}>
                <Typography
                  variant="subtitle2"
                  className={classes.inputEnabled}
                >
                  estado
                </Typography>
                <Select
                  disabled={shouldDisableFields}
                  variant="outlined"
                  name="status"
                  value={status || "1"}
                  onChange={(e) => handleChange(e)}
                  style={{ width: 150 }}
                >
                  {STATUS_VARIANTS?.map((status) => {
                    return (
                      <MenuItem key={status.id} value={status.id}>
                        {status.desc}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              {isView && (
                <Grid item xs={8}>
                  <Typography className={classes.editSwitchOpen}>
                    Enciende para editar
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={handleEdit}
                        onChange={handleEditChange}
                        name="edit-info"
                      />
                    }
                    label="Editar"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <RenderUpdateButton />
              </Grid>
              {(type === "edit" || type === "new") && (
                <Button
                  disabled={
                    type === "new" ? disableWhenIsNew : disableWhenIsEdit
                  }
                  className={classes.saveButton}
                  type="contained"
                  color="primary"
                  onClick={() => saveInformationHandler(currentUserInformation)}
                >
                  <Typography className={classes.updateInfoBtn}>
                    {type === "new" ? "Crear" : "Guardar"}
                  </Typography>
                </Button>
              )}
            </Grid>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default UserinformationBodyModal;
